import React from "react"
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
import * as dashboardStyles from "../../styles/page/dashboard.module.scss"
import * as chartStyles from "../../styles/components/chart.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"
import Modal from "react-modal"
import specialCharacterReplace from "../../utils/special-character-replace"

import SearchFilter from "../../components/parts/chart/search-filter"
import ImageBox from "../../components/parts/image-box"

// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      s15_1_2_1: false,
      s15_1_2_3: false,
      s15_1_2_4: false
    }

    this.searchInit = {
      filter1Init: 5,
      filter2Init: 0,
      filter3Init: 'technology-trend',
      current: props.location.pathname
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : true
    })
  }
  closeModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : false
    })
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          parentLink="/dashboard/"
          parentTitle="ダッシュボード"
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(title) }} />
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>
          
        <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>半導体材料の特性</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s15_1_2_1"}>
                <img src={'../../images/chart/s15-1-2-1.png'} alt="半導体材料の特性" width={559} />
              </button>
              <ImageBox
                open={this.state.s15_1_2_1}
                close={this.closeModal}
                name={"s15_1_2_1"}
                src={'/images/chart/s15-1-2-1.png'}
                alt="半導体材料の特性"
                width={1260}
                height={565} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）経済産業省貿易経済協力局「令和2年度重要技術管理体制強化事業 （重要産業技術基盤調査）」（2021年3月）、JST「低炭素社会実現に向けた政策立案のための提案書「酸化ガリウムの新規ワイドギャップ半導体としての電子デバイス応用へ向けた技術開発課題」（2019年1月）より作成</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>産業用インバータロードマップ</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s15_1_2_3"}>
                <img src={'../../images/chart/s15-1-2-3.png'} alt="産業用インバータロードマップ" width={559} />
              </button>
              <ImageBox
                open={this.state.s15_1_2_3}
                close={this.closeModal}
                name={"s15_1_2_3"}
                src={'/images/chart/s15-1-2-3.png'}
                alt="産業用インバータロードマップ"
                width={1102}
                height={650} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.sicalliance.jp/page/page000384.html" target="_blank" rel="noreferrer noopener">SiC Alliance, "SiC Road Map"</a>（2020年12月）</p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>スイッチングデバイスロードマップ</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s15_1_2_4"}>
                <img src={'../../images/chart/s15-1-2-4.png'} alt="スイッチングデバイスロードマップ" width={559} />
              </button>
              <ImageBox
                open={this.state.s15_1_2_4}
                close={this.closeModal}
                name={"s15_1_2_4"}
                src={'/images/chart/s15-1-2-4.png'}
                alt="スイッチングデバイスロードマップ"
                width={1076}
                height={632} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.sicalliance.jp/page/page000384.html" target="_blank" rel="noreferrer noopener">SiC Alliance, "SiC Road Map"</a>（2020年12月）</p>
              </div>
            </div>
            <div className={dashboardStyles.chartAreaItem}></div>
          </div>

        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`